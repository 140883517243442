<template>
  <action-dispatcher
    id="index-lessons-list"
    class="card"
    action="courses/getLessons"
    :parameters="module.id"
  >
    <div class="card-body">
      <h4 class="mb-0">{{ module.name }}</h4>
      <div class="text-secondary mb-3">Módulo {{ module.sort }}</div>

      <div class="my-n4">
        <component
          v-for="lesson in lessons"
          :key="lesson.id"
          :is="isBlocked(lesson) ? 'div' : 'router-link'"
          class="row text-light text-decoration-none"
          :to="{ name: 'lesson', params: { id: lesson.id, slug: lesson.slug } }"
        >
          <div class="col-lg-4 my-4">
            <div class="position-relative">
              <img :src="lesson.cover" class="img-fluid" />
              <div v-if="isBlocked(lesson)" class="icon-wrapper">
                <i class="icon-locked"></i>
              </div>
            </div>
          </div>

          <div class="col-lg-8 my-4">
            <h4 class="font-serif">{{ lesson.name }}</h4>
            <div v-html="lesson.description"></div>
          </div>
        </component>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    module: Object,
  },
  computed: {
    ...mapState("courses", ["lessons"]),
    ...mapGetters("accounts", ["isAdmin"]),
  },
  methods: {
    isBlocked(lesson) {
      return !this.isAdmin && (this.module.is_blocked || lesson.is_blocked);
    },
  },
};
</script>

<style lang="scss" scoped>
#index-lessons-list {
  background: #131212;
  box-shadow: 4px 4px 10px rgba(39, 29, 102, 0.04);
  border-radius: 4px;
  margin-top: 24px;

  img {
    border-radius: 10px;
  }

  .icon-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    max-width: 64px;
    max-height: 64px;
    min-width: 64px;
    min-height: 64px;
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 32px;
    }
  }
}
</style>
