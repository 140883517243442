<template>
  <div class="index-lesson-modules">
    <div class="row">
      <div class="col-lg-3 col-md-6" v-for="mod in modules" :key="mod.id">
        <button
          :style="`background-image: url(${mod.cover})`"
          @click="activate(mod)"
        >
          <div
            v-if="active && active.id === mod.id"
            class="active-border"
          ></div>
          <div v-if="mod.is_blocked" class="block">
            <i class="icon-locked"></i>
            <span>
              Este conteúdo estará disponível em breve. {{ publishedDate(mod) }}
            </span>
          </div>
          <!-- <div class="title">
            Módulo {{ index + 1 }}
            <h2 class="font-serif">{{ mod.name }}</h2>
          </div> -->
        </button>
      </div>
    </div>

    <lessons-list v-if="active" :key="active.id" :module="active" />
  </div>
</template>

<script>
import LessonsList from "./LessonsList";
import { mapGetters } from "vuex";
import { sortBy } from "lodash";

export default {
  components: { LessonsList },
  data() {
    let active = null;
    this.$store.getters["courses/currentCourse"].modules.forEach((module) => {
      if (!module.is_blocked) active = module;
    });

    return {
      active,
    };
  },
  computed: {
    ...mapGetters("courses", ["currentCourse"]),
    ...mapGetters("accounts", ["isAdmin"]),
    modules() {
      return sortBy(this.currentCourse.modules, ["sort"]);
    },
  },
  methods: {
    publishedDate(module) {
      const date = new Date(module.published_at);
      return date.toLocaleDateString();
    },
    activate(module) {
      if (!this.isAdmin && module.is_blocked) {
        this.$message.error(
          `Este conteúdo estará disponível a partir de ${this.publishedDate(
            module
          )}`
        );
      } else {
        this.active = module;
        setTimeout(() => {
          document.getElementById("index-lessons-list").scrollIntoView();
        }, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.index-lesson-modules {
  button {
    height: 0;
    padding-bottom: 140%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    border: none;
    position: relative;
    text-align: left;

    .active-border {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 4px solid #a7b719;
      z-index: 1;
    }

    .block {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      color: white;
      background-color: rgba(0, 0, 0, 0.79);
      padding: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;

      i {
        font-size: 24px;
        margin-right: 16px;
      }
    }

    .title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 80px 16px 16px;
      background-image: linear-gradient(to bottom, transparent, black);
      color: #bdbdbd;

      h2 {
        color: white;
        margin-bottom: 0;
      }
    }
  }
}
</style>
