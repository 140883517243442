<template>
  <action-dispatcher action="podcasts/getAll" class="index-podcast-list">
    <el-collapse>
      <el-collapse-item
        v-for="(podcast, index) in podcasts"
        :title="`${index + 1}. ${podcast.name}`"
        :name="podcast.id"
        :key="podcast.id"
      >
        <div class="card" v-for="lesson in podcast.lessons" :key="lesson.code">
          <div class="card-body">
            {{ lesson.name }}
            <div
              class="text-secondary small mb-1"
              v-html="lesson.description"
            ></div>
            <audio-player
              v-for="audio in lesson.audios"
              :audio="audio"
              :key="audio.id"
            />
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </action-dispatcher>
</template>

<script>
import { mapState } from "vuex";
import AudioPlayer from "./AudioPlayer";

export default {
  components: { AudioPlayer },
  computed: {
    ...mapState("podcasts", ["podcasts"]),
  },
};
</script>

<style lang="scss">
.index-podcast-list {
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 16px;

      .el-collapse-item__header {
        border-radius: 10px;
        padding: 32px 24px 32px 32px;
        background-color: #131212;
        color: white;
        border: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        height: auto;

        &.is-active {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    .el-collapse-item__wrap {
      background: none;
      border-bottom: none;
      overflow: visible;

      .el-collapse-item__content {
        background-color: #131212;
        color: white;
        padding: 0 32px 32px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .card {
          background: none;
          border: 1px solid #828282;

          &:not(:last-of-type) {
            margin-bottom: 32px;
          }
        }
      }
    }
  }

  ::v-deep p {
    margin-bottom: 0;
  }
}
</style>
