<template>
  <div class="index-audio-player">
    <audio :id="`audio${audio.id}`" crossorigin playsinline>
      <source :src="audio.media" type="audio/mp3" />
    </audio>
  </div>
</template>

<script>
import Plyr from "plyr";

export default {
  props: {
    audio: Object,
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    const element = document.getElementById(`audio${this.audio.id}`);
    this.player = new Plyr(element, {
      i18n: {
        speed: "Velocidade",
      },
    });
  },
};
</script>

<style lang="scss">
.index-audio-player {
  .plyr__controls {
    background: none;
    padding: 0;

    .plyr__controls__item:first-child {
      background: #009bc5;
      color: white;
      border-radius: 100%;
      padding: 10px;
      margin-right: 8px;

      .icon--not-pressed {
        margin-left: 2px;
        margin-right: -2px;
      }
    }
  }
}
</style>
